import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {AuthorService} from '../../../services/author.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-paid-account',
  templateUrl: './paid-message.component.html',
  styleUrls: ['./paid-message.component.sass']
})
export class PaidMessageComponent implements OnInit {
  confirmId: any;
  userStatusForm: FormGroup;
  toppings = new FormControl();
  toppingList: any;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private dateAdapter: DateAdapter<Date>,
      private authorService: AuthorService,
      private router: Router,
  ) {
    this.orderService.paidMessStatus.subscribe(cnt => this.confirmId = cnt);
    this.dateAdapter.setLocale('uk-UA');
  }

  ngOnInit() {
    this.authorService.getAllAuthorShort().subscribe(res => {
      this.toppingList = res;
    });
    this.userStatusForm = this.formBuilder.group({
      text: ['', Validators.required]
    });
  }

  close() {
    this.orderService.userPaid(null, null);
    this.confirmId = null;
  }

  get f() { return this.userStatusForm.controls; }

  confirm() {
    this.orderService.paidMailUser({
      text: this.f.text.value,
      id_user: this.toppings.value,
    }).subscribe(data => {
      this.router.navigate(['/manager/paid/' + this.toppings.value]);
    });
  }

  onSubmit() {
    if (!this.userStatusForm.valid || !this.toppings.value) {
      alert('Заповніть усі поля!');
      return;
    } else {
      this.confirm();
    }
  }


}
