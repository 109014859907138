import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UploderService} from '../../../services/uploader.service';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.sass']
})
export class AddFileComponent implements OnInit {
  confirmId: any;
  sended = true;
  addFile: FormGroup;
  filesSetData = [];
  allselectFiles = [];
  @ViewChild('filesadd') photoSet: ElementRef;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private uploderService: UploderService
  ) {
    this.orderService.addFile.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
    this.addFile = this.formBuilder.group({
    });
  }

  close() {
    this.orderService.orderAddFile(null, null, null);
    this.confirmId = null;
  }

  get f() { return this.addFile.controls; }

  confirm() {
    this.sended = false;
    this.uploadPhotoSet().then(file => {
      this.confirmId.file = file;
      this.orderService.addFileToOrder(this.confirmId).subscribe(data => {
        this.sended = true;
        this.filesSetData = [];
        this.allselectFiles = [];
        this.photoSet.nativeElement.value = '';
        this.close();
      });
    });
  }

  onSubmit() {

    if (!this.addFile.valid || this.allselectFiles.length == 0) {
      alert('Завантажте файл!');
      return;
    }
    this.confirm();
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;
      if (files) {
        for (const file of files) {
          this.allselectFiles.unshift(file);
        }
      }
    }
  }

  uploadPhotoSet() {
    return new Promise((resolve, reject) => {
      if (this.allselectFiles.length > 0) {
        const uploadData = new FormData();
        Array.from(this.allselectFiles).forEach((val: any, i: number) => {
          uploadData.append(`files_${i}`, val, val.name);
        });
        this.uploderService.uploadImage('user/uploadManager/', uploadData).subscribe((event: any) => {
          if (event && event.ok) {
            if (event && event.body && Array.isArray(event.body)) {
              for (const file of event.body) {
                this.filesSetData.push(file.file);
              }
              resolve(this.filesSetData);
            }
          }
          return true;
        });
      } else {
        resolve(null);
      }
    });
  }



}
