import { Component, Input, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {OrderService} from '../../../services/order.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  fullname: string;
  @Input() type: any;
  idUser: any;
  allMessage: any;
  allNotification: any;
  allNotificationAdmin: any;
  allNotificationPrice: any;
  allNotificationPriceManager: any;
  allPaidManager: any;
  openMenu: boolean;
  allNoRead: any;
  countAll: any;
  openMenus = false;
  constructor(
      private authService: AuthService,
      private orderService: OrderService,
      private route: ActivatedRoute,
      private router: Router,
  ) { }

  ngOnInit() {
    if (localStorage.currentManager) {
      this.idUser = JSON.parse(localStorage.currentManager).id;
    } else if (localStorage.currentAdmin) {
      this.idUser = JSON.parse(localStorage.currentAdmin).id;
    } else if (localStorage.currentUser) {
      this.idUser = JSON.parse(localStorage.currentUser).id;
    }

    if (this.idUser) {
      this.authService.getUserId(this.idUser).subscribe(res => {
        if (!res.id) {
          this.router.navigate(['/login']);
        }
        this.fullname = res.name + ' ' + res.lastname;
        this.getAllMessages();
        if (res.type != 1) {
          this.getAllNotificationAdmin();
          this.getAllNotificationPrice();
          setInterval(() => {
            this.getAllNotificationAdmin();
            this.getAllNotificationPrice();
          }, 22000);
        } else {
          this.getAllNotificationPriceManager();
          this.getAllPaid();
          setInterval(() => {
            this.getAllNotificationPriceManager();
            this.getAllPaid();
          }, 22000);
        }
        this.getAllNotification();
        setInterval(() => {
          this.getAllMessages();
        }, 20000);
        setInterval(() => {
          this.getAllNotification();
        }, 22000);
      });
      this.orderService.checkMessage.subscribe(() => {
        this.getAllMessages();
      });
      this.orderService.confirmNotification.subscribe(() => {
        this.getAllNotification();
      });
      this.orderService.confirmPaid.subscribe(() => {
        this.getAllPaid();
      });
      this.orderService.confirmNotificationAdmin.subscribe(() => {
        this.getAllNotificationAdmin();
      });
    }
  }

  getAllMessages() {
    this.countAll = 0;
    this.allMessage = 0;
    if (localStorage.currentUser) {
      this.orderService.getAllMessageUserAccount(this.idUser).subscribe(res => {
        this.allNoRead = res.map(item => {
          return this.countAll = this.countAll + item['total_noRead'];
        });
      });
      this.orderService.getAllMessageManagerCountAccount(this.idUser).subscribe(res => {
          return this.allMessage = this.allMessage + res;
      });
    } else {
      this.orderService.getAllMessageUser(this.idUser).subscribe(res => {
        this.allNoRead = res.map(item => {
          return this.countAll = this.countAll + item['total_noRead'];
        });
      });
      this.orderService.getAllMessageManagerCount(this.idUser).subscribe(res => {
        this.allMessage = this.allMessage + res;
      });
    }
  }

  getAllNotification() {
    if(this.idUser) {
      this.authService.getAllNotification(this.idUser).subscribe(res => {
        this.allNotification = res;
      });
    }
  }

  getAllNotificationAdmin() {
    if(this.idUser) {
      this.authService.getAllNotificationAdmin(1).subscribe(res => {
        this.allNotificationAdmin = res;
      });
    }
  }

  getAllNotificationPrice() {
    if(this.idUser) {
      this.authService.getAllNotificationPrice(1).subscribe(res => {
        this.allNotificationPrice = res;
      });
    }
  }

  getAllNotificationPriceManager() {
    if(this.idUser) {
      this.authService.getAllNotificationPriceManager(this.idUser).subscribe(res => {
        this.allNotificationPriceManager = res;
      });
    }
  }


  openNews() {
    if (this.idUser) {
      this.orderService.userNews(this.idUser);
    }
  }

  openStatus() {
    if (this.idUser) {
      this.orderService.userStatus(null, null, this.idUser);
    }
  }

  openNotification() {
    if (this.idUser) {
      this.orderService.userNotification(this.idUser);
    }
  }

  openNotificationAdmin() {
    if (this.idUser) {
      this.orderService.userNotificationAdmin(this.idUser);
    }
  }

  openNotificationPrice() {
    if (this.idUser) {
      this.orderService.userNotificationPrice(this.idUser);
    }
  }

  openNotificationPriceManager() {
    if (this.idUser) {
      this.orderService.userNotificationPriceManager(this.idUser);
    }
  }

  closeMenu() {
    this.openMenus = !this.openMenus;
  }

  openMessageAdmin() {
    if (this.idUser) {
      this.orderService.adminSend(true, this.idUser );
    }
  }

  openPaid(){
    if (this.idUser) {
      this.orderService.userPaidOpen(this.idUser);
    }
  }
  getAllPaid(){
    if(this.idUser) {
      this.authService.getAllPaid(this.idUser).subscribe(res => {
        this.allPaidManager = res;
      });
    }
  }


}
