import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators/';
import domain from './domain.config';

@Injectable({
    providedIn: 'root'
})
export class AuthorService {

    constructor(private _http: HttpClient) {
    }

    logoutEmitter: EventEmitter<any> = new EventEmitter();

    getAllAuthor() {
        return this._http.post<any>(`${domain}/user/getAllAuthor`, {});
    }

    getAllAuthorShort() {
        return this._http.post<any>(`${domain}/user/getAllAuthorShort`, {});
    }

    getAllAuthorFilter(id) {
        return this._http.post<any>(`${domain}/user/getAllAuthorFilter`, {id});
    }

    getAllAuthorManager() {
        return this._http.post<any>(`${domain}/user/getAllAuthorManager`, {});
    }

    getAllAuthorManagerAll(data) {
        return this._http.post<any>(`${domain}/user/getAllAuthorManagerAll`, {data});
    }

    getAllPaidManagerAll(data) {
        return this._http.post<any>(`${domain}/user/getAllPaidManagerAll`, {data});
    }

    getAllAuthorNewsById(id) {
        return this._http.post<any>(`${domain}/user/getAllAuthorNewsById`, {id});
    }

    getNewsAll() {
        return this._http.post<any>(`${domain}/user/getNewsAll`, {});
    }

    getAllOrderManager(data) {
        return this._http.post<any>(`${domain}/user/getAllOrderManager`, {data});
    }

    create(data) {
        return this._http.post<any>(`${domain}/user/createNewOrder`, {data});
    }

    getAllSpeciality() {
        return this._http.post<any>(`${domain}/user/getAllSpeciality`, {});
    }

    getAllAuthorOrders() {
        return this._http.post<any>(`${domain}/user/getAllAuthorOrders`, {});
    }

    getOrderUserPrice(data) {
        return this._http.post<any>(`${domain}/user/getOrderUserPrice`, {data});
    }

    getAllTypeWork() {
        return this._http.post<any>(`${domain}/user/getAllTypeWork`, {});
    }

    getOrderById(id) {
        return this._http.post<any>(`${domain}/user/getOrderById`, {id});
    }

    getAllActivityId(id) {
        return this._http.post<any>(`${domain}/user/getAllActivityId`, {id});
    }

    getStatusByUserIO(id) {
        return this._http.post<any>(`${domain}/user/getStatusByUserIO`, {id});
    }

    getOrderByDetailsId(id) {
        return this._http.post<any>(`${domain}/user/getOrderByDetailsId`, {id});
    }

    editOrder(data) {
        return this._http.post<any>(`${domain}/user/editOrder`, {data});
    }

    editSeeAddOrder(data) {
        return this._http.post<any>(`${domain}/user/editSeeAddOrder`, {data});
    }

    addNews(data) {
        return this._http.post<any>(`${domain}/user/addNews`, {data});
    }

    addSpeciality(data) {
        return this._http.post<any>(`${domain}/user/addSpeciality`, {data});
    }

    editSpeciality(data) {
        return this._http.post<any>(`${domain}/user/editSpeciality`, {data});
    }

    addTypework(data) {
        return this._http.post<any>(`${domain}/user/addTypework`, {data});
    }

    editTypework(data) {
        return this._http.post<any>(`${domain}/user/editTypework`, {data});
    }

    editNews(data) {
        return this._http.post<any>(`${domain}/user/updateNews`, {data});
    }

    getPageById(id) {
        return this._http.post<any>(`${domain}/user/getPageById`, {id});
    }

    getNewsById(id) {
        return this._http.post<any>(`${domain}/user/getNewsById`, {id});
    }

    getPageAll() {
        return this._http.post<any>(`${domain}/user/getPageAll`, {});
    }

    editPageById(data) {
        return this._http.post<any>(`${domain}/user/editPageById`, {data});
    }

    getAllReview(data) {
        return this._http.post<any>(`${domain}/user/getAllReview`, {data});
    }

    getAllOrderManagerNew(data) {
        return this._http.post<any>(`${domain}/user/getAllOrderManagerNew`, {data});
    }




}
